

.icons{
	background:#fff;

	.list-inline{
		display: table;
		width: 100%;
	}

	li{
		@media(max-width: 718px) {
			display: none;
		}
		width: 20%;
		text-align: center;
		display: table-cell;
	}
}

.icons-responsive {
	background:#fff;
	.list-inline{
		display: table;
		width: 100%;
	}

	li{
		@media(min-width: 718px) {
			display: none;
		}
		@media(max-width: 690px) {
			display: none;
		}
		width: 20%;
		text-align: center;
		display: table-cell;
	}
}

.icons-more-responsive {
	@media(min-width: 691px){
		display: none;
	}
	background: #fff;
	text-align: center;
	img {
		padding-bottom: 10px;
	}
}


.titulo{
	background:#fff;
	padding-top: 1px;
	padding-bottom: 1px;
	.down {
		margin-bottom: 20px;
	}
	h2{
		@media(max-width: 689px) {
		margin-bottom: -13px;
		}

		font-size: 28px;
		font-weight: 500;
		letter-spacing: 4px;
		text-align: center;
		color: #4d4d4d;
		position: relative;
		text-transform: uppercase;
		margin-bottom: 30px;

		&:after{
			content: ' ';
			bottom: -10px;
			margin: 0 auto;
			left: 0;
			right: 0;
			width: 430px;
			@media(max-width: 467px) {
				width: 280px;
			}
			position: absolute;
			height: 3px;
			background-color: #6697c4;
		}
	}
}

.seccion{
	padding-bottom: 30px;
	background-color: #fff;

	img{
		margin-top: 30px;
	}

	.container{
		background-color: #f2f2f2;
		padding-bottom: 30px;
	}

	h2{ 
		font-size: 22px;
		font-weight: 500;
		letter-spacing: 4px;
		color: #ffffff;
		background-color: #6697c4;
		padding: 14px 30px 14px 60px;
		display: inline-block;
		margin-left: -15px;
		margin-bottom: 30px;
		text-transform: uppercase;
	}
	
	p{
		padding-left: 45px;
		font-size: 14px;
		font-weight: 300;
		text-align: left;
		color: #4c4c4c;
	}
}

.frase{
	background: #fff;
	padding-top: 5px;
	padding-bottom: 25px;
	font-size: 24px;
	font-weight: 500;
	font-style: italic;
	line-height: 1.0;
	letter-spacing: 1.8px;
	text-align: center;
	color: #6697c4;
}
