
$font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font-family-serif:       Georgia, "Times New Roman", Times, serif;

$font-family-base:        $font-family-sans-serif;

$body-bg:    #003362;
$navbar-height:                    80px;
$navbar-default-bg:                #fff;
$navbar-default-border:            transparent;
$brand-primary: #003362;
$input-border-focus:             $brand-primary;
$modal-header-border-color: $brand-primary;
$navbar-margin-bottom:             0;
$navbar-border-radius:             0;
$input-border-radius:             0;
$navbar-default-link-color:                #4c4c4c;
$navbar-default-link-hover-color:          #03539d;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         #03539d;
$navbar-default-link-active-bg:            transparent;
$navbar-default-link-disabled-color:       #ccc;
$navbar-default-link-disabled-bg:          transparent;

$tooltip-arrow-color: $brand-primary;
$tooltip-bg: #fff;
$tooltip-color: $brand-primary;

$btn-border-radius-base: 0px;

$icon-font-path: '/fonts/';
