.home{
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: #fff;
	.container{

		padding-left: 0;
		padding-right: 0;
	}

	.carousel-caption {
		left: 0;
		text-align: left;
		padding-left: 60px;
		width: 50%;

		h5{
			font-size: 24px;
			font-weight: bold;
			font-style: italic;
			letter-spacing: 1.9px;
			text-align: left;
			color: #ffffff;
			margin-bottom: 0;
			@media (max-width: $screen-sm-max){
				font-size: 18px;
			}
		}

		p{
			font-size: 20px;
			letter-spacing: 1.5px;
			@media (max-width: $screen-sm-max){
				display: none;
			}
		}
	}
}


.nosotros{
	padding-bottom: 30px;
	background-color: #fff;

	.plasticos{
		margin-top: 30px;
	}



	.container{
		background-color: #f2f2f2;
		padding-bottom: 30px;
		
	}

	h2{ 
		font-size: 28px;
		font-weight: 500;
		letter-spacing: 4px;
		color: #ffffff;
		background-color: #6697c4;
		padding: 14px 30px 14px 60px;
		display: inline-block;
		margin-left: -15px;
		margin-bottom: 30px;
		text-transform: uppercase;

	}
	p{
		padding-left: 45px;
		font-size: 18px;
		font-weight: 300;
		text-align: left;
		color: #4c4c4c;
	}
}

.banner{
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: #fff;

	.container{
		background-color: #03539d;
		border: solid 1px #2d2620;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	p{
		font-size: 16px;
		font-weight: 300;
		line-height: 1.5;
		letter-spacing: 1.2px;
		text-align: center;
		color: #ffffff;
	}
}

.servicios{
	background-color: #fff;
	padding-bottom: 30px;
	h2{
		font-size: 28px;
		font-weight: 500;
		letter-spacing: 4px;
		text-align: center;
		color: #4d4d4d;
		position: relative;
		text-transform: uppercase;
		margin-bottom: 30px;

		&:after{
			content: ' ';
			bottom: -10px;
			margin: 0 auto;
			left: 0;
			right: 0;
			width: 430px;
			@media(max-width: 459px) {
				width: 230px;
			}
			position: absolute;
			height: 3px;
			background-color: #6697c4;
		}
	}

	.service .col-md-3{
		min-height: 368px;
	}

	.plasticos{
		height: 100%;
		background-position: center center;
		background-size: cover;
		background-image: url(/images/plasticos-servicio.jpg);
	}

	.moldes{
		height: 100%;
		background-position: center center;
		background-size: cover;
		background-image: url(/images/moldes-servicio.jpg);
	}

	.bottom{
		@media (max-width: $screen-sm-max){
			margin-top: 30px;
		}
	}

	.texto{
		background-color: #6697c4;
		padding-top: 60px;
		padding-bottom: 60px;
		h4{
			font-size: 24px;
			@media(max-width: 350px) {
				font-size: 18px;
			}
			font-weight: 500;
			letter-spacing: 3px;
			text-align: center;
			color: #ffffff;
		}
		p{
			font-size: 17px;
			font-weight: 300;
			font-style: italic;
			letter-spacing: 1.7px;
			text-align: center;
			color: #ffffff;
		}
	}
}

.exito{
	position: relative;
	background-color: #fff;
	padding-top: 30px;
	padding-bottom: 30px;

	.container{

		padding-left: 0;
		padding-right: 0;
	}

	h2{ 
		font-size: 28px;
		font-weight: 500;
		letter-spacing: 4px;
		color: #ffffff;
		background-color: #6697c4;
		padding: 14px 30px 14px 60px;
		@media(max-width: 578px) {
			padding: 14px 30px 14px 5%;
		}
		@media(max-width: 500px) {
			margin-top: 10px;
		}
		@media(max-width: 358px) {
			font-size: 16px;
			padding-top: 6px;
			padding-bottom: 6px;
			margin-top: 0;
		}
		display: inline-block;
		position: absolute;
		z-index: 10;
	}

	.carousel{
		position: relative;
		.carousel-caption{
			width: 50%;
			height: 100%;
			background-color: rgba(#03539d, 0.8);
			top: 0;
			left: 0;
			padding-top: 100px;
			padding-left: 60px;
			padding-right: 66px;

			@media (max-width: $screen-sm-max){
				display: none;
			}

			h4{
				font-size: 24px;
				letter-spacing: 1.1px;
				text-align: left;
				color: #ffffff;
			}

			p{
				font-size: 18px;
				text-align: justify;
				color: #ffffff;
			}
		}
		@media (min-width: $screen-md-min){
			.carousel-indicators{
				left: 35%;
				width: 40%;
			}
		}
		a {
			color: #ffffff;
			font-size: 18px;
			text-align: left;
		}
	}
}

.contacto{
	background-color: #fff;
	padding-bottom: 30px;
	input, textarea{
		background-color: #f2f2f2;
		border: solid 1px #003362;
	}

}
#map {
	.container {
		width: auto;
	}
	min-height: 300px;
	@media(max-width: 689px) {
		height: 200px;
	}
}
