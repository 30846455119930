@import './variables.scss';
@import 'node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss';
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700');
@import './home.scss';
@import './seccion.scss';



.navbar-brand {
	padding-top: 16px;
	padding-bottom: 16px;
	& > img {
		max-height: 50px;
		padding-top: 5px;
		@media(max-width: 991px) {
			width: 290px;
			margin-top: 5px;
			height: auto;
		}
		@media(max-width: 380px) {
			width: 220px;
			height: auto;
		}
	}
}


.navbar-default .navbar-nav {

	> li{
		text-align: center;
		&.active,
		&:hover, 
		&:focus {
			&:before {
				width: 80%;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
			}
			> a, > a:hover, > a:focus {
				color: #6697c4;
			}
		}

		&:before{
			width: 0%;
			display: inline-block;
			border-bottom: 3px solid #6697c4;
			position: absolute;
			bottom: 20px;
			left: 0;
			-webkit-transition: width .3s ease-in-out;
			-o-transition: width .3s ease-in-out;
			transition: width .3s ease-in-out;
			content: ' ';
			position: absolute;
			
			@media (max-width: $screen-sm-max){
				bottom: 0px;
			}
		}

		> a {
			position: relative;
			-webkit-transition: color 3s ease-in-out;
			-o-transition: color .3s ease-in-out;
			transition: color .3s ease-in-out;
		} 
	}
}

body, #content {
	position: relative;
}

.sliders {
	background: #fff;
	padding-top: 10px;
	padding-bottom: 30px;

	.container{
		padding-bottom: 30px;
		padding-right: 0;
		padding-left: 0;
		border-bottom: 1px solid #003362;
	}

	h4{
		font-weight: bold;
		font-style: italic;
		letter-spacing: 1.4px;
		text-align: center;
		color: #ffffff;
		background: #003362;
		padding-top: 7px;
		padding-bottom: 7px;
	}

	.carousel-control{
		background: transparent !important;
		.glyphicon {
			color: #03539d;
		}
	}

	.carousel-indicators li{
		border-color: #03539d;
		&.active{
			background: #03539d
		}
	}

	.unpadded {
		margin-left: 0px;
		margin-right: 0px;
	}

	
}

.images{
	background: #fff;
	padding-top: 10px;
	padding-bottom: 30px;
	.grid{
		.grid-item{
			width: 50%;
			padding: 10px 15px;
		}
	}
}
.footer{
	padding-top: 30px;
	padding-bottom: 30px;
	ul{
		li{
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 1px;
			text-align: left;
			padding-top: 8px;
			padding-bottom: 8px;

			span{
				display: block;
			}
			.title{
				color: #fff;
				text-transform: uppercase;
				@media (max-width: $screen-xs-max){
					text-align: center;
				}
			}
			.subtitle{
				color: #c4c4c4;
				@media (max-width: $screen-xs-max){
					text-align: center;
				}
			}
		}
	}

	.line-right{
		border-right: 2px solid #ffffff;

		@media (max-width: $screen-xs-max){
			border: none;
		}
	}
	img {
		padding-top: 20px;
		max-width: 310px;
		height: auto;
	}

	.original {
		@media(max-width: 991px) {
			display: none;
		}
	}

	.responsive {
		@media(min-width: 992px) {
			display:none;
		}
		@media(max-width: 372px) {
			margin-left: -20px;
			max-width: 180px;
			padding-top: 0px;

		}
	}

}
